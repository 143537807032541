/* You can add global styles to this file, and also import other style files */
.CodeMirror
  height: 100%



:host ::ng-deep .p-button.p-button-secondary
  background: #95C3D9


.monospace
  font-family: monospace


// light mode
:root
  --kk-bg-color: #fff
  --kk-bg-color1: #eee
  --kk-bg-color2: #efefef
  --kk-bg-color3: #ddd
  --kk-color: #333
  --redish: linear-gradient(90deg, rgba(255,230,230,1) 0%, rgba(227,193,193,1) 100%)
  --redish1: rgba(255,230,230,1)
  --redish2: red
  --orangish: linear-gradient(90deg, rgba(255,248,230,1) 0%, rgba(247,231,176,1) 100%)
  --orangish1: rgba(255,248,230,1)
  --orangish2: orange
  --greenish: linear-gradient(90deg, rgba(230,255,230,1) 0%, rgba(193,227,193,1) 100%)
  --greenish1: rgba(230,255,230,1)
  --greenish2: green
  --blueish: linear-gradient(90deg, rgba(230,243,255,1) 0%, rgba(193,209,227,1) 100%)
  --link-color: #0270c6

// dark mode
[data-theme="dark"]
  --kk-bg-color: #1a212b
  --kk-bg-color1: #1f2d40
  --kk-bg-color2: #1A212B
  --kk-bg-color3: #444
  --kk-color: rgba(255, 255, 255, 0.70)
  --redish: linear-gradient(90deg, rgba(120,10,10,1) 0%, rgba(50,5,5,1) 100%)
  --redish1: rgba(120,10,10,1)
  --redish2: #f11
  --orangish: linear-gradient(90deg, rgba(125,47,0,1) 0%, rgba(70,27,0,1) 100%)
  --orangish1: rgba(125,47,0,1)
  --orangish2: #dd1
  --greenish: linear-gradient(90deg, rgba(0,90,0,1) 0%, rgba(5,50,5,1) 100%)
  --greenish1: rgba(0,90,0,1)
  --greenish2: #0f0
  --blueish: linear-gradient(90deg, rgb(0,37,125,1) 0%, rgba(0, 17, 70) 100%)
  --link-color: #44ACFD


body
  background-color: var(--kk-bg-color)
  color: var(--kk-color)

a
  text-decoration: unset
  color: var(--link-color)

a:visited
  color: var(--link-color)

a:hover
  text-decoration: underline


.round-shadow
    padding: 10px 5px
    border: 1px solid var(--kk-bg-color1)
    border-radius: 5px
    box-shadow: 0 1px 3px var(--kk-bg-color3)
    position: relative

.round-shadow-selected
    padding: 10px 5px
    border: 2px solid #aaa
    border-radius: 5px
    box-shadow: 0 1px 3px #aaa
    position: relative
